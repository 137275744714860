import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var routers = [{
  path: '/corpUserCard',
  component: function component() {
    return import('@/views/corpUserCard/index.vue');
  },
  meta: {
    title: '个人名片',
    keepAlive: false
  }
}, {
  path: '/editUserCard',
  component: function component() {
    return import('@/views/corpUserCard/edit.vue');
  },
  meta: {
    title: '编辑名片',
    keepAlive: false
  }
}, {
  path: '/previewUserCard',
  component: function component() {
    return import('@/views/corpUserCard/preview.vue');
  },
  meta: {
    title: '预览名片',
    keepAlive: false
  }
}, {
  path: '/selectTemp',
  component: function component() {
    return import('@/views/corpUserCard/selectTemp.vue');
  },
  meta: {
    title: '选择模板',
    keepAlive: false
  }
}];
export default routers;